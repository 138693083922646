import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'

import { Button } from '../../components/button.component';
import { FormControl } from '../../components/form-control.component';
import { Input } from '../../components/input.component';
import { FormContext } from '../../utils/form.context';
import { setPassword } from '../../services/users.service';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { RegisterLayout } from '../../components/register-layout.component';
import { useQueryParam } from 'use-query-params';

export default function ActivateAccount(props) {

    const { t, navigate } = useI18next();

    const dispatch = useDispatch();

    const [formValues, setFormValues] = useState({});

    const [errors, setErrors] = useState(null);
    const [tokenError, setTokenError] = useState('');

    //@Todo: Check if token actually exist/is ok, otherwise navigate away.

    const [ email ] = useQueryParam('email');
    const [ token ] = useQueryParam('token');
    const [ isSamePassword, setIsSamePassword ] = useState(true);

    const onActivateAccount = useCallback((e) => {
        e.preventDefault();
        setErrors(null);
        
        if (formValues.password === formValues.confirmPassword) {
            setIsSamePassword(true);
            setPassword({ ...formValues,  emailAddress: email, token })
                .then(response => {
                    navigate(`/account/verification/?email=${encodeURIComponent(email)}&token=${encodeURIComponent(token)}`);
                })
                .catch(error => {
                    setErrors(error.response.data.message);
                });
        } else {
            setIsSamePassword(false);
        }
    }, [formValues, setErrors, dispatch, navigate]);

    useEffect(() => {
        if (errors) {
            const tokenError = errors.find(e => e.property === 'token');
            if (tokenError) {
                setTokenError(t('Your invitation link has expired, please contact the administrator.'));
            } else {
                setTokenError('');
            }
        }
    }, [ errors ]);

    const handleOnChange = useCallback(e => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    }, [formValues, setFormValues]);

    return <RegisterLayout disableSecureLayout={true} hideBackBtn={true} title={t('Create a password')} className="create-account">
        <div className="section section--login">
            <FormContext.Provider value={errors}>
                {tokenError ? (
                    <p className="t-sans t-orange">{tokenError}</p>
                ) : (
                    <>
                    <p className="t-sans t-grey9">{t('Enter the password you would like to use with you account.')}</p>
                    <form onSubmit={onActivateAccount} className="flex-form">
                        <FormControl label={t('Password')} name="password">
                            <Input name="password" placeholder="******" value={formValues.password || ''} onChange={handleOnChange} type="password" />
                        </FormControl>
                        <FormControl className={isSamePassword ? '' : 'invalid-password'} label={t('Confirm password')} name="confirmPassword">
                            <Input name="confirmPassword" placeholder="******" value={formValues.confirmPassword || ''} onChange={handleOnChange} type="password" onFocus={() => setIsSamePassword(true)} />
                        </FormControl>
                        
                        <div className="buttons space-top">
                            <Button type="submit">{t('Confirm')}</Button>
                        </div>
                    </form>
                    </>
                )}
            </FormContext.Provider>
        </div>
    </RegisterLayout>;
}
